import React from "react";
import { Breadcrumb, Button, Card, Col, Input, Layout, Radio, Row, Typography, message } from "antd";
import { Link, Navigate } from "react-router-dom";

import { POST_API, POST_CATCH, getToken, titleWeb, verifyConfig } from "../../../services/api";


// CONTROLLER
import Page403 from '../../Controller/Page403';

class AdminRelatoriosList extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        select: null,
        initial: null,
        final: null,
        load: false,
    }

    back = "/painel/relatorios";

    routes = [
        {title: <Link to={this.back}>Relatórios</Link>},
        {title: 'Gerar relatório'}
    ]

    componentDidMount = () => {
        titleWeb('Relatórios')
    }

    onReport = () => {
        if (!this.state.select) {
            message.warning('Selecione um tipo de relatório válido')
            return
        }
        if (!this.state.initial || !this.state.final) {
            message.warning('Selecione um período válido')
            return
        }
        this.setState({load: true})
        POST_API(`/report/${this.state.select}.php`, { token: getToken(), initial: this.state.initial, final: this.state.final }).then(rs => rs.blob()).then(res => {
            
            var name = '';
            switch (this.state.select) {
                case 'process':
                    name = 'Relatório de processos'; break;
                case 'client':
                    name = 'Relatório de clientes'; break;
                case 'financial':
                    name = 'Relatório de financeiro'; break;
                case 'task':
                    name = 'Relatório de tarefas'; break;
                default:
                    break;
            }
            // Cria um link temporário para o download
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(res);
            link.href = url;
            link.download = `${name} de ${this.state.initial} à ${this.state.final}.xls`; // Nome do arquivo que será baixado
            document.body.appendChild(link);
            link.click(); // Simula o clique no link para iniciar o download
            document.body.removeChild(link); // Remove o link temporário
        }).catch(() => POST_CATCH()).finally(() => this.setState({load: false }))
    }

    render = () => {
        
        if (this.state._navigate) return (<Navigate to={this.back} />)
        if (!verifyConfig(`${this.props.per}.${this.props.type}`)) return <Page403 />

        return (
            <Layout>
                <Layout.Content className={`admin-container`}>
                    <Row gutter={[8,8]} align={'middle'}>
                        <Col span={18}>
                            <Breadcrumb items={this.routes} />
                        </Col>
                        <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        </Col>
                        <Col span={24}>
                            <Card>
                                <Typography style={{fontWeight: 700}}>Selecione o tipo de relatório</Typography>
                                <Radio.Group onChange={(e) => this.setState({select: e.target.value})} style={{flexDirection: 'column', display: 'flex'}}>
                                    <Radio value="process">Relatório de processos</Radio>
                                    <Radio value="client">Relatório de clientes</Radio>
                                    <Radio value="financial">Relatório de financeiro</Radio>
                                    <Radio value="task" disabled>Relatório de tarefas</Radio>
                                </Radio.Group>
                                <Typography style={{fontWeight: 700, marginTop: 10}}>Selecione o periodo</Typography>
                                <Row gutter={[8,8]} align={'middle'}>
                                    <Col>
                                        <Input max={this.state.final} disabled={!this.state.select} type="date" onChange={(e) => this.setState({initial: e.target.value})} />
                                    </Col>
                                    <Col>à</Col>
                                    <Col>
                                        <Input min={this.state.initial} disabled={!this.state.select} type="date" onChange={(e) => this.setState({final: e.target.value})} />
                                    </Col>
                                </Row>
                                <Button loading={this.state.load} onClick={this.onReport} type="primary" style={{marginTop: 20}} disabled={!this.state.select||!this.state.initial||!this.state.final}>Gerar relatório</Button>
                            </Card>
                        </Col>
                    </Row>
                </Layout.Content>
            </Layout>
        )

    }

}

export default AdminRelatoriosList;