import React from "react";
import { Col, Row, Breadcrumb, Typography, message, Form, Input, Select, Button, Card, Avatar, Tag } from "antd";
import { Link } from "react-router-dom";
import { POST_API } from "../../services/api";

class WebAdvogado extends React.Component {

    state = { data: [], options: [], loading: true }

    breadcrumb = [
        { title: <Link to='/'><Typography className="home-breadcrumb-link">Home</Typography></Link> },
        { title: <Typography className="home-breadcrumb-link active">Advogados</Typography> }
    ]

    componentDidMount = () => {

        this.onSearch({})

        POST_API('/specialty/search.php', { token: 'X' }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({options: res.data.map((item) => ({ label: item.NAME, value: item.ID }))})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })

    }

    onSearch = (values) => {
        this.setState({loading: true})
        POST_API('/credential/search.php', { token: 'X', filter: JSON.stringify({...values, CREDENTIAL_TYPE_ID: 2}) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({data: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
            this.setState({loading: false})
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        })
    }

    render = () => {

        return (
            
            <Row>
                <Col span={24} className="home-col-black">
                    <Row align={'middle'} className="home-row-breadcrumb">
                        <Col span={12}>
                            <Breadcrumb className="home-breadcrumb" items={this.breadcrumb} />
                        </Col>
                        <Col span={12}>
                            <Typography className="home-breadcrumb-title">Advogados</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[16,16]} className="home-row-page">
                        <Col span={24}><Typography className="home-row-page-text-title">ADVOGADOS</Typography></Col>
                        <Col span={24}>
                            <Form layout="vertical" onFinish={this.onSearch}>
                                <Row gutter={[16, 0]} align={'bottom'}>
                                    <Col xl={8} lg={8} md={14} sm={14} xs={24}>
                                        <Form.Item label="Nome" name="NAME">
                                            <Input type='text' placeholder="Busque pelo nome" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={6} lg={6} md={10} sm={10} xs={24}>
                                        <Form.Item label="Especialidade" name="SPECIALTY_ID">
                                            <Select
                                                allowClear
                                                style={{ width: '100%' }}
                                                placeholder="Busque por especialidade"
                                                options={this.state.options}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={5} lg={5} md={8} sm={10} xs={16}>
                                        <Form.Item label="Cidade" name="CITY">
                                            <Input type='text' placeholder="Busque pela cidade" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={2} lg={2} md={4} sm={4} xs={8}>
                                        <Form.Item label="UF" name="STATE">
                                            <Input type='text' placeholder="UF" />
                                        </Form.Item>
                                    </Col>
                                    <Col xl={3} lg={3} md={12} sm={10} xs={24}>
                                        <Button loading={this.state.loading} htmlType="submit" block type="primary" style={{marginBottom: 24}}>Filtrar</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                        { this.state.data.length > 0 ? this.state.data.map((item, index) => (
                            <Col key={index} xl={8} lg={12} md={12} sm={24} xs={24}>
                                <Link to={`/advogados/${item.ID}`}>
                                    <Card hoverable actions={[ <Row gutter={[4,4]} style={{marginLeft: 10,marginRight: 10, flexWrap: 'nowrap', overflowX: 'auto'}}>
                                        { item.SPECIALITY?.length > 0 ? item.SPECIALITY.map((vl) => <Col><Tag style={{marginRight: 0}} color="var(--primary)">{vl}</Tag></Col> ) : <Col>Sem especialidaes</Col> }
                                    </Row> ]}>
                                        <Card.Meta
                                            avatar={<Avatar size={100} src={item.INTER_AVATAR} />}
                                            title={item.NAME}
                                            description={ item.CITY ? <p>{item.CITY} / {item.STATE}</p> : 'Sem endereço' }
                                        />
                                    </Card>
                                </Link>
                            </Col>
                        )) : <Col span={24}><Typography>Nenhum advogado encontrado</Typography></Col> }
                    </Row>
                </Col>
            </Row>
           
        )

    }

}

export default WebAdvogado;